import { Container, Grid } from '@material-ui/core'
import React from 'react'
import Slider from 'react-slick';
import Layout from '../../components/layout'
import GridRow from '../../components/_grid-row'


import pageStyles from '../../assets/scss/class-internal.module.scss'
import Made2moveImg from '../../images/edu/the5amclub.png';

export default function The5amclub() {
    const sliderImages = [
        Made2moveImg
    ];

    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 3.6,
        slidesToScroll: 3.6,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.05,
                    slidesToScroll: 2.05,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.02,
                    slidesToScroll: 1.02,
                    infinite: false,
                    dots: true
                }
            },
        ]
    };

    return (
        <React.Fragment>
            <Layout pageName="Education" backLink="/education">
                <div className={["class-internal", pageStyles.wrapper].join(" ")}>
                    <Container maxWidth="xl">
                        <GridRow>
                            {/* Slider section */}
                            <Grid container spacing={3} justify="center">
                                <Grid item xs={12}>
                                    {sliderImages?
                                    <Slider  {...settings}>
                                        {
                                            sliderImages.map(img => {
                                                return (
                                                    <div>
                                                        <img src={img}/>
                                                    </div>
                                                )
                                            })
                                        }

                                    </Slider>
                                    :
                                    ""

                                    }
                                </Grid>
                                {/* Content section */}
                                <Grid item xs={12} lg={8}>
                                    <div className={pageStyles.header}>
                                        <h2 className="h2">The 5AM Club</h2>
                                    </div>
                                    <div className={pageStyles.desc}>
                                        <p>
                                            Marianna is a passionate leader and developer of people, with 20+ years of experience in Marketing and Communications.Having worked in different settings in various countries in leading communication roles prior to joining Orascom as the Marketing and Communication Director. 
                                            <br /><br />
                                            She was listed on the prestigious list revealed by Forbes Middle East of 50 of the Middle East's top marketing and communication executives. Since  2016 she has been part of a premium digital mentoring program by Robin Sharma "The Circle of Legends". Over a 15-year period Robin has developed a strikingly powerful executive coaching process while working with top CEOs and organization. 
                                            <br /><br />
                                            Marianna is a speaker,podcaster,and above all,certified health and nutrition coach with a focus on Prime-Time Health (lifestyle, exercise,attitude ,nutrition) and workplace wellness solutions, building a healthier workforce. 
                                            <br />
                                            <br />
                                            This is workshop 3 in our GGF Education Series, free for all GGFers and offered at a small fee of 350 EGP for family and friends.
                                            <br />
                                            <br />
                                            To inquire or book, please send an email to info@beachmonkeyz.com.
                                        </p>
                                    </div>
                                    <Grid item xs={12} md={6} lg={5}>
                                        <div className={pageStyles.reserveButton}>
                                            <a href="mailto:info@beachmonkeyz.com" className={pageStyles.emailLink}>
                                                Send us an e-mail
                                            </a>
                                        </div>
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </GridRow>
                    </Container>
                </div>
            </Layout>
        </React.Fragment>
    )
}
